const LANGUAGE = {
    NAVBAR: {
        HOME: 'HOME',
        ABOUT: 'ABOUT',
        PORTFOLIO: 'PORTFOLIO',
        CONTACT_ME: 'CONTACT ME'
    },
    HOME: {
        SHORTNAME: 'Rafi Irfansyah',
        BIO_DESC: 'Hardworking person interested in Software Developer.',
        DOWNLOAD_CV: 'DOWNLOAD RESUME'
    },
    ABOUT_PAGE: {
        LANGUAGE: "LANGUAGE",
        ABOUT: [
            {
                NUMBER: 7,
                TITLE: 'PROJECTS'
            },
            {
                NUMBER: 2,
                TITLE: 'YEARS'
            },
            {
                NUMBER: 5,
                TITLE: 'CODE LANGUAGE'
            }
        ],
        TITLE: 'What I Do and Learn.',
        SUBTITLE: 'I have learned a bunch of code and tools to upgrade my skill and achieve my goals as a Software Developer.'
    },
    PORTFOLIO_PAGE: {
        TITLE: 'PORTFOLIO',
        SUBTITLE: 'Featured Works',
        DESCRIPTION: 'I want to share some of my works all accross the code language i have learn'
    },
    PROJECTS: {
        TITLE: "PROJECTS",
        SUBTITLE: "SHOWCASE OF MY PROJECTS ON SOFTWARE DEVELOPMENT"
    },
    BUTTON: {
        SEE_PROJECTS: 'SEE ALL PROJECTS',
        DOWNLOAD_CV: 'DOWNLOAD CV',
        CLOSE: 'CLOSE',
        SUBMIT: 'SUBMIT'
    },
    WARNING: {
        UNDER_CONSTRUCTION: 'UNDER CONSTRUCTION',
        UNDER_CONSTRUCTION_DETAIL: 'IM SORRY BUT THIS FEATURE IS STILL UNDER CONSTRUCTION. WILL PUBLISH IT AS SOON AS IT DONE',
        RESTRICTED_PROJECT: 'CONFIDENTIAL PROJECT',
        RESTRICTED_PROJECT_DETAIL: 'IM SORRY THIS PROJECT IS RESTRICTED TO ACCESS BECAUSE OF THE CONFIDENTIALITY OF THE COMPANY.'
    },
}

export default LANGUAGE